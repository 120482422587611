"use client";

import { cn } from "@/utils/tw";
export const Divider = (props: {
  className?: string;
  vertical?: boolean;
  children?: React.ReactNode;
  variant?: "default" | "black";
}) => {
  if (props.vertical) return <div className={cn("w-[1px] grow-0 border-r border-stroke-gray", {
    "border-black": props.variant === "black"
  }, props.className)} />;
  return <div className={cn("relative my-4 flex w-full items-center", {
    "border-black": props.variant === "black"
  }, props.className)} data-sentry-component="Divider" data-sentry-source-file="Divider.tsx">
      <div className="h-[1px] flex-1 bg-gray-200" />
      {props.children}
      <div className="h-[1px] flex-1 bg-gray-200" />
    </div>;
};